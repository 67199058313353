<template>
    <modal ref="modalEditarPrecio" titulo="Cambiar precio" icon="money" :cargando="loading" @guardar="aceptar">
        <ValidationObserver ref="validator">
            <div class="row mx-0 mb-5 justify-center">
                <ValidationProvider v-slot="{errors}" tag="div" class="col-10 mb-3" :rules="`required|greaterThanZero|max_value:99999999|promo:${model.promo_valor},${model.promo_valor_formateado}`" name="valor">
                    <label class="pl-3 text-muted"> Precio </label>
                    <div class="d-middle">
                        <div class="bg-whitesmoke2 py-1 px-1 mr-1 border" style="border-radius:12px 0px 0px 12px">
                            {{ sigla_actual }}
                        </div>
                        <input-miles-moneda v-model="model.valor" :id-moneda="id_moneda_cedis" maxlength="13" />
                    </div>
                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    <div v-if="model.id_promocion != null" class="row mx-0">
                        <div class="bg-light-f5 text-general border mt-1 cr-pointer br-8 border d-middle-center px-3" @click="eliminarPromocion">
                            <i class="icon-tag-remove" />
                            <p class="pl-3">Eliminar promoción</p>
                        </div>
                    </div>
                </ValidationProvider>
                <!-- <div class="col-12">
                </div> -->
                <ValidationProvider v-slot="{errors}" tag="div" class="col-10" rules="required|max:300" name="observación">
                    <label class="pl-3 f-15"> Observación de ajuste </label>
                    <el-input v-model="model.justificacion" type="textarea" :rows="4" />
                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                </ValidationProvider>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'
import CedisProductos from '~/services/cedis/cedis_productos'

export default {
    data(){
        return{
            loading:false,
            model:{
                valor: null,
                id_producto: null,
                id_cedis: null,
                id_promocion: null,
                promo_valor: null,
                promo_descuento: null,
                promo_valor_formateado: null,
                justificacion: null
            },
            id_moneda_cedis: 0,
            titulo: '',
            sigla_actual: ''
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis'
        })
    },
    methods: {
        toggle(info){
            //console.log({info});
            this.model.id_cedis = info.id_cedis?info.id_cedis: this.id_cedis
            this.model.valor = info.valor
            this.model.id_producto = info.id_producto
            this.model.id_promocion = info.id_promocion
            this.model.promo_valor = info.promo_valor
            this.model.promo_descuento = info.promo_descuento
            this.model.promo_valor_formateado = info.promo_valor_formateado
            this.id_moneda_cedis = info.id_moneda
            this.sigla_actual = this.calcularSiglaActual(this.model.id_cedis)
            this.$refs.modalEditarPrecio.toggle();
        },
        async aceptar(){
            try {
                const valid =  await this.$refs.validator.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'Campos obligatorios', 'warning')
                    return false
                }
                this.loading = true
                const {data} = await CedisProductos.editarPrecio(this.model)
                this.loading = false
                this.notificacion('Mensaje', 'Datos actualizados', 'success')
                this.$refs.modalEditarPrecio.toggle();

                this.$emit('actualizar', data.cedisProducto.valor)
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        async eliminarPromocion(cedi){
            try {
                const params = {
                    id_cedis: this.model.id_cedis,
                    id_producto: this.model.id_producto,
                    id_promocion: this.model.id_promocion,
                }
                this.loading = true
                const {data} = await CedisProductos.eliminarPromocion(params)
                this.notificacion('Mensaje', 'Datos actualizados', 'success')
                this.model.id_promocion = null
                this.model.promo_valor = null
            } catch (e){
                this.error_catch(e)
            }finally {
                this.loading = false
            }
        },
    }
}
</script>

<style>

</style>
