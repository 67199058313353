import axios from 'axios'

const API_PREFIX = 'cedis-productos'

const ENDPOINTS = {
    tablaCedisProductos: (id_producto,params = {}) => axios(`${API_PREFIX}/${id_producto}/construir-tabla`,{params}),
    listaProveedores: (params = {}) => axios(`${API_PREFIX}/lista-proveedores`,{params}),
    getVolumenesProductos: (params = {}) => axios(`${API_PREFIX}/lista-volumenes`,{params}),
    actualizarCedisProductosValores: (payload = {}) => axios.put(`${API_PREFIX}/update-cedis-valores`,payload),
    cambiarValoresCedis: (payload = {}) => axios.post(`${API_PREFIX}/cambiar-valores-cedis`,payload),
    updateDisponibleCedi: (payload = {}) => axios.put(`${API_PREFIX}/update-diponible-cedi`,payload),
    updateInventarioProveedor: (payload = {}) => axios.put(`${API_PREFIX}/update-inventario-proveedor`,payload),
    venderSinStock: (payload = {}) => axios.put(`${API_PREFIX}/vender-sin-stock`,payload),
    updateTodosCedis: (payload = {}) => axios.put(`${API_PREFIX}/update-todos-cedi`,payload),
    actualizarCedisProductosStock: (payload = {}) => axios.put(`${API_PREFIX}/update-cedis-producto-stock`,payload),
    editarPrecio: (payload = {}) => axios.put(`${API_PREFIX}/editar-precio`,payload),
    cambioVolumen: (payload = {}) => axios.put(`${API_PREFIX}/cambio-volumen`,payload),
    eliminarPromocion: (payload = {}) => axios.post(`${API_PREFIX}/eliminar-promocion`,payload),
};

export default ENDPOINTS
